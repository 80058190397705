import React from "react";
import { kebabCase } from "lodash";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const TagsPage = ({
  data: {
    allKdccmsNews: { edges: posts },
    site: {
      siteMetadata: { title }
    }
  }
}) => {
  let tags = {};
  posts.forEach(edge => {
    const pTags =
      edge.node.tags.split(",").map(item => item.trim().toLowerCase()) || [];

    pTags.forEach(tag => {
      if (tag === "") return;
      if (!tags[tag]) tags[tag] = 0;
      tags[tag] += 1;
    });
  });

  if (!tags || tags.length === 0) {
    return null;
  }

  return (
    <Layout>
      <SEO
        title={`Tags | News | ${title}`}
        keywords={[
          "news",
          "tags",
          "electrical",
          "Philippines",
          "design",
          "build",
          "system",
          "supply",
          "troubleshoot",
          "repair",
          "equipments",
          "test",
          "manage",
          "bms"
        ]}
      />
      <div className="container space-2 space-3-top--lg">
        <section className="section">
          <div className="container content">
            <div className="columns">
              <div
                className="column is-10 is-offset-1"
                style={{ marginBottom: "6rem" }}
              >
                <h1 className="title is-size-2 is-bold-light">Tags</h1>
                <ul className="taglist">
                  {Object.keys(tags).map(tag => (
                    <li key={tag}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>
                        {tag} ({tags[tag]})
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allKdccmsNews(limit: 1000) {
      edges {
        node {
          tags
        }
      }
    }
  }
`;
